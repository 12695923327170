<template>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="created_at"
        :sortOrder="-1"
        :showDelete="0"
        newButtonName=""
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="controller" header="Module" :sortable="true" style="width: 200px"></Column>
            <Column field="user.email" :header="$t('navigation.users')" :sortable="true" style="width: 300px"></Column>
            <Column field="formatted_log" :header="$t('overview.actions')" :sortable="false"></Column>
            <Column field="created_at" :header="$t('overview.date')" :sortable="true" style="width: 200px">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at, 'logDateTime')}}
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import logService from '@/services/LogService'
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: logService,
            itemFormRef: null,
            navName: 'navigation.logs',
            companyTypes: [],
            selectFields: []
        }
    },
    mounted() {
        //Not customers allowed
        if(store.getters.getUserType == 2){
            this.$router.push({name: 'Home'});
        }
        this.itemFormRef = this.$refs.ItemForm;
    },
    methods: {

    }
    
}
</script>